import React from 'react';

/* DOM */
import ReactDOM from 'react-dom/client';
const root = ReactDOM.createRoot(document.getElementById("inject"));

/* Router */
import { BrowserRouter } from 'react-router-dom';
import Routes from "./routes";

/* Redux */
import { Provider } from 'react-redux';
import store from './reducers'

/* Sentry */
import * as Sentry from "@sentry/browser";
if(process.env.NODE_ENV !== "development") {
    Sentry.init({dsn: 'https://9f5ce0653c6f40868c6525a9ff21bc3e@o376668.ingest.sentry.io/5285584', debug: false});
}

/* FullStory */
import FullStory from 'react-fullstory';

const AppStore = () => (
    <Provider store={store}>
        <BrowserRouter>
            <FullStory org={'S90AH'} />
            <Routes />
        </BrowserRouter>
    </Provider>
);

root.render(<AppStore />);
