import React from 'react';
import styled from 'styled-components';

/* Components */
import { colors } from "../../../components/colors";
import { Section, Body, ContactTitle } from '../components/components';
import {FontBody18, FontBody21} from '../../../components/fonts';

const Logo = styled.div`
  width:fit-content;
  filter:grayscale(1);
  & img {
    filter:grayscale(1);
    width:200px;
    height:auto;
  }
`;
const FirmBio = styled(FontBody18)`
color: ${props => props.$color};
padding:0;
margin:0;
  vertical-align: middle;
  @media screen and (max-width: 1680px) {
    font-size: 16px;
    line-height: 1;
  }
`;
const PreviewBlack = styled(FontBody21)`
  line-height: 2;
  @media screen and (max-width: 748px) {
    font-size: 18px !important;
  }
`;
const PreviewBlackSmall = styled(FontBody18)`
  line-height: 2;
`;

export default function Firm({
     firm_logo = "",
     firm_name = "",
     firm_bio = "",
     firm_address = "",
     firm_sec = "",
 }) {
    return (
        <Section $backgroundColor={colors.canvaBrown}>
            <ContactTitle $color={colors.primary100}>Firm</ContactTitle>
            <Body>
                {firm_logo !== null && <Logo><img src={firm_logo} alt={firm_name}/> </Logo>}
                {(firm_bio !== null && firm_bio !== "") && <PreviewBlack>{firm_bio}</PreviewBlack>}
                {(firm_address !== null && firm_address !== "") && <PreviewBlackSmall>{firm_address}</PreviewBlackSmall>}
                <FirmBio >Firm SEC/CRD # {firm_sec}</FirmBio>
            </Body>
        </Section>
    )
}