import React from 'react';
import styled from 'styled-components';

/* Components */
import { colors } from '../../../components/colors';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../components/button';
import {images} from "../../../components/images";

const ButtonContainer = styled.div`
  position: absolute;
  left: 25px;
  top: 20px;
  z-index: 1;
  & button {
    padding: 0 !important;
  }
  @media screen and (max-width: 780px) {
    left: 15px;
    top: 15px;
  }

`;

export default function Back(props) {
    const q = props.q;
    const setQ = props.setQ;

    const onBack = () => {setQ(q - 1)}

    return (
        <ButtonContainer>
            {q > 1 &&
                <Button
                    isValid={true}
                    clickable={true}
                    onClick={onBack}
                    label={"BACK"}
                    icon={images.backArrow}
                    iconType={"img"}
                    iconSide={"left"}
                    colorButtonValid={"transparent"}
                    colorButtonInvalid={"transparent"}
                    colorTextValid={colors.white}
                    colorTextInvalid={colors.white}
                />
            }
        </ButtonContainer>
    );
};