import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Components */
import { colors } from './colors';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontBody14 } from "./fonts";
import { images } from "./images";

const Box = styled.div`
    padding: 0 40px;
    width: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100px;
    background-color: ${colors.footer};
    
    @media screen and (max-width: 1088px) {
      display: none;
    }
`;
const Logo = styled.div`
    position: absolute;
    left: 0;
    top: 0;
`;
const Img = styled.img`
  height:50px;
    padding-top: 16px;
    margin-left: -4px;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.$gap}px;
    padding: 30px 0 20px 0;
    height: calc(100% - 60px);
    align-items: center;
    justify-content: center;
    position: relative;
`;
const Text = styled(FontBody14)`
    color: ${colors.footerText};

`;
const OneLink = styled.a`
    color: ${colors.footerText};
    cursor: pointer;
`;

export default function Footer() {

    return (
        <Box>
            <Row $gap={0}>
                <Logo><Img src={images.logoGray} height={"30"} alt={""} /></Logo>
                <Text style={{marginTop:'60px'}}>Copyright © 2024 Willow Network, Inc.</Text>
            </Row>
            <Row $gap={20}>
                <Text><OneLink href={"https://www.trustwillow.com/privacy-policy"} target={"_blank"}>Privacy Policy</OneLink></Text>
                <Text><OneLink href={"https://www.trustwillow.com/contact"} target={"_blank"}>Contact Us</OneLink></Text>
                <Text><OneLink href={"https://www.trustwillow.com/terms-of-use"} target={"_blank"}>Terms of Use</OneLink></Text>
            </Row>
            <Row $gap={4}>
                <FontAwesomeIcon
                    icon={faLock}
                    style={{"color": colors.primary30, fontSize: '14px'}}
                />
                <Text>Your information is secure.</Text>
            </Row>
        </Box>
    );
};