import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import Cookies from "js-cookie";
import {DateTime} from "luxon";

/* Containers */
import Side from "./nav/containers/side";
import MyAdvisors from "./myadvisors/page";
import MyProfile from "./myprofile/page";
import MyMessages from "./mymessages/page";
import Refer from "./refer/page";
import Support from "./support/containers/flow";

/* Utils */
import {checkT1} from "../../utils/checks/checkt1";
import {checkT2} from "../../utils/checks/checkt2";
import {checkRoptSt} from "../../utils/checks/checkroptst";
import {checkMatched} from "../../utils/checks/checkmatched";
import {setMatched, setRopt, setSt, setT1, setT2} from "../../utils/store";
import {tryPostMailchimp, tryPostMixpanel} from "../../utils/tracking";

/* Components */
import {LogoLoading} from "../../components/loading";

/* Middleware */
import {tryPostGetMatches} from "./myadvisors/middleware/getmatches";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  //padding-bottom: 60px;
  height: 100vh;
  //height: calc(100vh - 60px);
  width: 100%;
`;
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  //padding-bottom: 60px;
  height: 100vh;
  //height: calc(100vh - 60px);
  width: calc(100% - 320px);
  margin-left: 320px;
  @media screen and (max-width: 1088px) {
    width: 100%;
    margin-left: 0;
  }
`;

const calcRoptRange = (value) => {
    if(value <= 100000) {
        return(1)
    } else if(value < 250000) {
        return(2)
    } else if(value < 500000) {
        return(3)
    } else if(value < 750000) {
        return(4)
    } else if(value < 1000000) {
        return(5)
    } else if(value < 1500000) {
        return(6)
    } else if(value < 2500000) {
        return(7)
    } else if(value < 5000000) {
        return(8)
    } else if(value >= 5000000) {
        return(9)
    } else {
        return(1)
    }
}

export default function Matches() {
    const [isLoading, setIsLoading] = useState(true);
    const t1Store = useSelector(state => state.session.t1);
    const t2Store = useSelector(state => state.session.t2);
    const emailStore = useSelector(state => state.session.email);
    const roptStore = useSelector(state => state.session.ropt);
    const stStore = useSelector(state => state.session.st);
    const matchedStore = useSelector(state => state.session.matched);
    const utm = {
        "utm_campaign": useSelector(state => state.session.utm_campaign),
        "utm_content": useSelector(state => state.session.utm_content),
        "utm_term": useSelector(state => state.session.utm_term),
        "utm_source": useSelector(state => state.session.utm_source),
        "utm_medium": useSelector(state => state.session.utm_medium),
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(location.pathname.includes("matching")) {
            tryMatch()
        } else {
            tryValidate()
        }
    }, [location.pathname]);

    const tryMatch = async () => {

        // User ID
        const {t1, isNewConsumer} = await checkT1(t1Store);
        if (t1 === null || isNewConsumer) {
            return navigate("/login");
        }
        await dispatch(setT1({"t1": t1}));

        // Already Matched
        await dispatch(setMatched({"matched": false}));

        // Collection Method
        const { t2, mixpanelEvent } = await checkT2(t2Store);
        if (t2 === undefined || t2 === "") {
            return navigate("/login");
        }
        await dispatch(setT2({"t2": t2}));

        // Need Survey Results
        if ((roptStore === null || stStore === null) && t2 === "FORM" && process.env.NODE_ENV !== "development") {
            return navigate("/login");
        }

        // AI Set ROPT and ST
        if ((roptStore === null || stStore === null) && t2 === "AI" && process.env.NODE_ENV !== "development") {
            const { ropt, st } = await checkRoptSt();
            if(ropt === undefined || st === undefined) {
                return navigate("/login");
            }
            await dispatch(setRopt({"ropt": ropt}));
            await dispatch(setSt({"st": st}));
        }

        // Match
        const reqMatches = {t1: t1, t2: t2, ropt: calcRoptRange(roptStore), st: stStore, details: true, newmap: true};
        const matchData = await tryPostGetMatches(reqMatches);
        await Cookies.set("matched", "true");
        await dispatch(setMatched({"matched": true}));

        // Mixpanel Matched
        const reqMixpanel = {
            "userEmail": null,
            "anonId": t1Store,
            "event": "clientAcquisitionRequestMatches",
            "environment": "PRODUCTION",
            ...utm,
            ...reqMatches
        }
        await tryPostMixpanel(reqMixpanel);

        // Mixpanel All Advisors
        const advisorIds = matchData.data.map(a => { return a.id });
        const reqMixpanelView = {
            ...reqMixpanel,
            "event": "clientAcquisitionViewMatches",
            "ids": advisorIds,
            "id1": advisorIds[0],
            "id2": advisorIds[1],
            "id3": advisorIds[2],
        }
        await tryPostMixpanel(reqMixpanelView);

        // Mixpanel Each Advisor
        matchData.data.forEach((a) => {
            let reqMixpanelSeparates = {
                ...reqMixpanel,
                "event": "clientAcquisitionViewMatchesIds",
                "advisorId": a.id,
                "advisorName": a.firstName + " " + a.lastName,
            }
            return tryPostMixpanel(reqMixpanelSeparates);
        });

        // Mailchimp Matched & Each Advisor
        const ADVREC1NAM = matchData.data[0]?.firstName + " " + matchData.data[0]?.lastName === " " ? {} : {"ADVREC1NAM": matchData.data[0]?.firstName + " " + matchData.data[0]?.lastName}
        const ADVREC2NAM = matchData.data[1]?.firstName + " " + matchData.data[1]?.lastName === " " ? {} : {"ADVREC2NAM": matchData.data[1]?.firstName + " " + matchData.data[1]?.lastName}
        const ADVREC3NAM = matchData.data[2]?.firstName + " " + matchData.data[2]?.lastName === " " ? {} : {"ADVREC3NAM": matchData.data[2]?.firstName + " " + matchData.data[2]?.lastName}
        const ADVREC1IMG = matchData.data[0]?.additionalProfileInformation?.photos[0] === null ? {} : {"ADVREC1IMG": matchData.data[0]?.additionalProfileInformation?.photos[0]}
        const ADVREC2IMG = matchData.data[1]?.additionalProfileInformation?.photos[0] === null ? {} : {"ADVREC2IMG": matchData.data[1]?.additionalProfileInformation?.photos[0]}
        const ADVREC3IMG = matchData.data[2]?.additionalProfileInformation?.photos[0] === null ? {} : {"ADVREC3IMG": matchData.data[2]?.additionalProfileInformation?.photos[0]}
        const ADVREC1CER = matchData.data[0]?.certifications.join(", ") === null ? {} : {"ADVREC1CER": matchData.data[0]?.certifications.join(", ")}
        const ADVREC2CER = matchData.data[1]?.certifications.join(", ") === null ? {} : {"ADVREC2CER": matchData.data[1]?.certifications.join(", ")}
        const ADVREC3CER = matchData.data[2]?.certifications.join(", ") === null ? {} : {"ADVREC3CER": matchData.data[2]?.certifications.join(", ")}
        await tryPostMailchimp({
            "email": emailStore,
            "tags": {
                "EMAIL": emailStore,
                "ACQVIEWDIR": "TRUE",
                ...ADVREC1NAM,
                ...ADVREC2NAM,
                ...ADVREC3NAM,
                ...ADVREC1IMG,
                ...ADVREC2IMG,
                ...ADVREC3IMG,
                ...ADVREC1CER,
                ...ADVREC2CER,
                ...ADVREC3CER,
                "ACQUPDATED": DateTime.now().toFormat("MM/dd/yyyy"),
            }
        })
        return navigate("/advisors")

    }

    const tryValidate = async () => {

        // User ID
        const { t1, isNewConsumer}  = await checkT1(t1Store);
        if((t1 === null || isNewConsumer) && process.env.NODE_ENV !== "development") {
            return navigate("/login");
        }
        await dispatch(setT1({"t1": t1}));

        // Already Matched
        const matched = await(checkMatched(isNewConsumer, matchedStore));
        if(!matched && process.env.NODE_ENV !== "development") {
            return navigate("/login");
        }
        await dispatch(setMatched({"matched": true}));

        setIsLoading(false)

    }

    if (isLoading && location.pathname.includes("matching")) {
        return (<LogoLoading pad={60} />)
    }

    return (
        <Page>
            <Side />
            <Inner>
                {location.pathname === "/advisors" && <MyAdvisors />}
                {location.pathname === "/profile" && <MyProfile />}
                {location.pathname === "/messages" && <MyMessages />}
                {location.pathname === "/refer" && <Refer />}
            </Inner>
            <Support />
        </Page>
    );
};