import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {DateTime} from 'luxon';
import Cookies from 'js-cookie'
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

/* Middleware */
import {tryPostGetMatches} from './middleware/getmatches';

/* Utils */
import {tryPostMailchimp, tryPostMixpanel} from '../../utils/tracking';
import {checkT1} from "../../utils/checks/checkt1";
import {checkT2} from "../../utils/checks/checkt2";
import {checkMatched} from "../../utils/checks/checkmatched";
import {checkRoptSt} from "../../utils/checks/checkroptst";

/* Components */
import {FontBody16} from "../../components/fonts";
import {LogoLoading} from "../../components/loading";

/* Containers */
import Nav from "./containers/nav";
import CarouselEmbla from "./containers/carouselembla";
// import Match from './containers/match';

/* Actions */
import {setMatched, setRopt, setSt, setT1, setT2} from "../../utils/store";
// import {Carousel} from "./containers/carousel";

const Page = styled.div`//
  display: flex;
  flex-direction: column;
  gap: 20px;
  //padding-bottom: 60px;
  height: 100vh;
  //height: calc(100vh - 60px);
  width: 100%;
`;
const AllMatches = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  //width: calc(100% - 60px);
  //padding: 0 30px;
  gap: 60px;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1088px) {
    //max-width: 600px;
  }
`;
const NoRecords = styled(FontBody16)`
  margin: 0 auto;
  width: calc(100% - 60px);
  padding: 0 30px;
`;

export default function Matches() {
    const t1Store = useSelector(state => state.session.t1);
    const t2Store = useSelector(state => state.session.t2);
    const matchedStore = useSelector(state => state.session.matched);
    const roptStore = useSelector(state => state.session.ropt);
    const stStore = useSelector(state => state.session.st);
    const [advisorRequest, setAdvisorRequest] = useState(null);
    const [advisorData, setAdvisorData] = useState(null);
    const [allMatches, setAllMatches] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const utmCampaign = useSelector(state => state.session.utm_campaign)
    const utmContent = useSelector(state => state.session.utm_content)
    const utmTerm = useSelector(state => state.session.utm_term)
    const utmSource = useSelector(state => state.session.utm_source)
    const utmMedium = useSelector(state => state.session.utm_medium)


    useEffect(() => {
        init()
    }, []);

    const calcRoptRange = (value) => {
        if(value <= 100000) {
            return(1)
        } else if(value < 250000) {
            return(2)
        } else if(value < 500000) {
            return(3)
        } else if(value < 1000000) {
            return(4)
        } else if(value < 5000000) {
            return(5)
        } else {
            return(6)
        }
    }

    const init = async () => {

        // User ID
        const {t1, isNewConsumer} = await checkT1(t1Store);
        if (isNewConsumer) {
            return navigate("/");
        }
        await dispatch(setT1({"t1": t1}));

        // Already Matched
        const matched = await (checkMatched(isNewConsumer, matchedStore));
        await dispatch(setMatched({"matched": matched}));

        // Collection Method
        const { t2, mixpanelEvent } = await checkT2(t2Store);
        if (t2 === undefined || t2 === "") {
            return navigate("/");
        }
        await dispatch(setT2({"t2": t2}));

        // Need Survey Results
        if (!matched && (roptStore === null || stStore === null) && t2 === "FORM" && process.env.NODE_ENV !== "development") {
            return navigate("/");
        }

        // AI Set ROPT and ST
        if (!matched && (roptStore === null || stStore === null) && t2 === "AI" && process.env.NODE_ENV !== "development") {
            const { ropt, st } = await checkRoptSt();
            if(ropt === undefined || st === undefined) {
                return navigate("/");
            }
            await dispatch(setRopt({"ropt": ropt}));
            await dispatch(setSt({"st": st}));
        }

        let reqMatches;

        // Match
        if (!matched) {
            // Request Match
            reqMatches = {t1: t1, t2: t2, ropt: calcRoptRange(roptStore), st: stStore, details: true, newmap: true};
        } else {
            // Get Previous Match
            reqMatches = {t1: t1, cache: true};
        }

        const matchData = await tryPostGetMatches(reqMatches);
        const reqMixpanel = {
            "userEmail": null,
            "anonId": t1Store,
            "event": "clientAcquisitionRequestMatches",
            "environment": "PRODUCTION",
            custom_source: utmSource,
            custom_content: utmContent,
            custom_term : utmTerm,
            custom_medium: utmMedium,
            custom_campaign: utmCampaign,
            ...reqMatches
        }
        tryPostMixpanel(reqMixpanel);
        await Cookies.set("matched", "true");
        await dispatch(setMatched({"matched": true}));

        setAdvisorData(matchData.data)
        setAdvisorRequest(reqMatches)

    }

    useEffect(() => {
        if(advisorData !== null && advisorRequest !== null) {
            runTracking()
            createAdvisorObjects()
        }
    }, [advisorData, advisorRequest])

    const runTracking = () => {
        const advisorIds = advisorData.map(a => { return a.id });
        const reqMixpanel = {
            "userEmail": null,
            "anonId": t1Store,
            "event": "clientAcquisitionViewMatches",
            "environment": "PRODUCTION",
            ...advisorRequest,
            "ids": advisorIds,
            "id1": advisorIds[0],
            "id2": advisorIds[1],
            "id3": advisorIds[2],
            custom_source: utmSource,
            custom_content: utmContent,
            custom_term : utmTerm,
            custom_medium: utmMedium,
            custom_campaign: utmCampaign,
        }
        tryPostMixpanel(reqMixpanel);
        tryPostMailchimp({ "t1": t1Store, "tagId": "ACQVIEWDIR", "tagValue": "TRUE" })
        // tryPostMailchimp({ "t1": t1Store, "tagId": "ADVREC1NAM", "tagValue": advisorData[0].firstName+" "+advisorData[0].lastName })
        // tryPostMailchimp({ "t1": t1Store, "tagId": "ADVREC2NAM", "tagValue": advisorData[1].firstName+" "+advisorData[1].lastName })
        // tryPostMailchimp({ "t1": t1Store, "tagId": "ADVREC3NAM", "tagValue": advisorData[2].firstName+" "+advisorData[2].lastName })
        // tryPostMailchimp({ "t1": t1Store, "tagId": "ADVREC1IMG", "tagValue": advisorData[0].additionalProfileInformation.photos[0] })
        // tryPostMailchimp({ "t1": t1Store, "tagId": "ADVREC2IMG", "tagValue": advisorData[1].additionalProfileInformation.photos[0] })
        // tryPostMailchimp({ "t1": t1Store, "tagId": "ADVREC3IMG", "tagValue": advisorData[2].additionalProfileInformation.photos[0] })
        // tryPostMailchimp({ "t1": t1Store, "tagId": "ADVREC1CER", "tagValue": advisorData[0].certifications.join(", ") })
        // tryPostMailchimp({ "t1": t1Store, "tagId": "ADVREC2CER", "tagValue": advisorData[1].certifications.join(", ") })
        // tryPostMailchimp({ "t1": t1Store, "tagId": "ADVREC3CER", "tagValue": advisorData[2].certifications.join(", ") })
        tryPostMailchimp({ "t1": t1Store, "tagId": "ACQUPDATED", "tagValue": DateTime.now().toFormat("MM/dd/yyyy") })
        advisorData.forEach((a) => {
            let reqMixpanelSeparates = {
                "userEmail": null,
                "anonId": t1Store,
                "event": "clientAcquisitionViewMatchesIds",
                "environment": "PRODUCTION",
                ...advisorRequest,
                "advisorId": a.id,
                "advisorName": a.firstName + " " + a.lastName,
                custom_source: utmSource,
                custom_content: utmContent,
                custom_term : utmTerm,
                custom_medium: utmMedium,
                custom_campaign: utmCampaign,
            }
            tryPostMixpanel(reqMixpanelSeparates);
        });
    }

    const createAdvisorObjects = () => {
        setAllMatches(advisorData.reduce((filtered, a) => {
            let photos = a.additionalProfileInformation.photos.filter(p => { return (p !== null) })
            let logos = a.additionalProfileInformation.logos.filter(l => { return (l !== null) })
            let date = a.badActorDate;
            let advisorRecord = {
                ...a,
                ...a.additionalProfileInformation,
                photos: photos.length > 0 ? photos[0] : null,
                logos: logos.length > 0 ? logos[0] : null,
                priority: false, // is sponsored advisor
                active: true, // is advisor active (expiration not hit)
                minAssets: parseInt(a.minimumInvestableAssets),
                gr: a.gender,
            };
            if (date) {
                // bad actor
            } else {
                filtered.push(advisorRecord)
            }
            return filtered;
        }, []))
    }

    if (allMatches === null) {
        return (<LogoLoading pad={60} />)
    }

    return (
        <Page>
            <Nav />
            <AllMatches>
                {allMatches.length === 0
                    ? <NoRecords>No advisors found</NoRecords>
                    : <CarouselEmbla slides={allMatches} options={{loop: true}} />
                // <Carousel>
                //         {allMatches.map((a, index) => (
                //             <Match key={index} a={a} />
                //         ))}
                //
                // </Carousel>
                }
            </AllMatches>
        </Page>
    );
};