import styled from 'styled-components';

/* Components */
import { FontTitle40 } from "../../../components/fonts";

export const Section = styled.div`
  width: calc(100% - 100px);
  padding: 60px 50px;
  gap: 60px;
  @media screen and (max-width: 1680px) {
    width: calc(100% - 120px);
    padding: 40px 60px;
    gap: 40px;
  }
  @media screen and (max-width: 720px) {
    width: calc(100% - 60px);
    padding: 60px 30px;
  }
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${props => props.$backgroundColor};
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const ContactTitle = styled(FontTitle40)`
  font-size: 60px !important;
  @media screen and (max-width: 748px) {
    font-size: 40px !important;
  }
  line-height: 1;
  color: ${props => props.$color};
`;