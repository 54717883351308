import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger';

/* Reducers */
import sessionReducer from './utils/store';

const store = configureStore({
    reducer: {
        session: sessionReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;