import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

/* Middleware */
import { tryPostMixpanel, tryPostMixpanelEnrich } from '../utils/tracking';

/* Components */
import { LogoLoading } from "../components/loading";

/* Actions */
import {setT1, setT2, setMatched, setCampaign, setMedium, setContent, setTerm, setSource} from '../utils/store';

/* Utils */
import { testIfSet } from "../utils/checks/checkcommon";
import { checkT1 } from "../utils/checks/checkt1";
import { checkT2 } from "../utils/checks/checkt2";
import { checkMatched } from "../utils/checks/checkmatched";

/* Pages */
import Survey from "./survey/page";
import AI from "./ai/page";

export default function Entry() {
    const [t2Local, setT2Local] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const t1Store = useSelector(state => state.session.t1);
    const t2Store = useSelector(state => state.session.t2);
    const matchedStore = useSelector(state => state.session.matched);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        init()
    }, []);

    const init = async () => {

        // User ID
        const { t1, isNewConsumer}  = await checkT1(t1Store);
        await dispatch(setT1({"t1": t1}));

        // Already Matched
        const matched = await(checkMatched(isNewConsumer, matchedStore));
        await dispatch(setMatched({"matched": matched}));
        if(matched && process.env.NODE_ENV !== "development") {
            return navigate("/matches");
        }

        // Collection Method
        const { t2, mixpanelEvent } = await checkT2(t2Store);
        await dispatch(setT2({"t2": t2}));

        // Tracking
        const urlParams = new URLSearchParams(window.location.search);
        const utmSource =  await testIfSet(urlParams.get('utm_source')) ? urlParams.get('utm_source') : undefined
        const utmMedium = await testIfSet(urlParams.get('utm_medium')) ? urlParams.get('utm_medium') : undefined
        const utmCampaign = await testIfSet(urlParams.get('utm_campaign')) ? urlParams.get('utm_campaign') : undefined
        const utmContent = await testIfSet(urlParams.get('utm_content')) ? urlParams.get('utm_content') : undefined
        const utmTerm = await testIfSet(urlParams.get('utm_term')) ? urlParams.get('utm_term') : undefined
        let reqMixpanel = {
            "userEmail": null,
            "anonId": t1,
            "entry": t2,
            "event": mixpanelEvent,
            "pathname": "https://my.trustwillow.com/",
            "environment": "PRODUCTION",
            "utm_source": utmSource,
            "utm_medium": utmMedium,
            "utm_campaign": utmCampaign,
            "utm_content": utmContent,
            "utm_term": utmTerm,
            "custom_source": utmSource,
            "custom_medium": utmMedium,
            "custom_campaign": utmCampaign,
            "custom_content": utmContent,
            "custom_term": utmTerm
        }
        const returnNull = (utm) => {return utm === undefined ? null : utm}
        await dispatch(setCampaign({"utm_campaign": returnNull(utmCampaign)}))
        await dispatch(setMedium({"utm_medium":returnNull(utmMedium)}))
        await dispatch(setContent({"utm_content":returnNull(utmContent)}))
        await dispatch(setTerm({"utm_term":returnNull(utmTerm)}))
        await dispatch(setSource({"utm_source":returnNull(utmSource)}))

        await tryPostMixpanelEnrich(reqMixpanel)
        await tryPostMixpanel(reqMixpanel)

        setT2Local(t2)
    }

    useEffect(() => {
        if(t2Local !== null) {
            setIsLoading(false)
        }
    }, [t2Local])

    if (isLoading) {
        return (<LogoLoading pad={60} />)
    }

    if(t2Local === "FORM") {
        return(<Survey />)
    }

    if(t2Local === "AI") {
        return(<AI />)
    }

};

// Post-Beta
// -------------------
// Move contact button on profile page below tags and above bio with dark background inside white area
// Make loading screen for matches/page.js since api response is 13+ seconds
// Button type hit enter to submit make it form based?
// Bring footer links on survey to this repo so it doesn't send user to trustwillow.com
// Add any google or meta conversions that go fish want
// hobby icon is still broken https://my.trustwillow.com/matches/5220
// google analytics events not working?
// Make back button on survey transparent background and remove the label back
// Fix wordpress footer with last column showing in the center
// https://docs.google.com/document/d/1dLOb2AxhsMpOt1NjnLY3DeAZMo-4QPuuYpBp4VbE9kg/edit
