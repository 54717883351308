import Cookies from "js-cookie";

/* Utils */
import { testIfSet } from "./checkcommon";

export const checkT2 = async (t2Store) => {
    const urlParams = new URLSearchParams(window.location.search);
    let t2 = "FORM";
    const t2Cookie = Cookies.get('t2');
    const t2Param = testIfSet(urlParams.get('t2')) ? urlParams.get('t2') : undefined;
    if(testIfSet(t2Cookie)) {
        t2 = t2Cookie;
    } else if(testIfSet(t2Param)) {
        t2 = t2Param;
        await Cookies.set("t2", t2);
    } else if(testIfSet(t2Store)) {
        t2 = t2Store;
        await Cookies.set("t2", t2);
    } else if(!testIfSet(t2Cookie) && !testIfSet(t2Param)) {
        const pathname = window.location.pathname;
        if(pathname === "/") {
            const rand = Math.random();
            if(rand < 0.5) {
                t2 = "FORM" // "AI" temporarily turned off 7/25/24 Ivan couldn't fix issue with AI on phone
            } else {
                t2 = "FORM"
            }
            await Cookies.set("t2", t2);
        } else {
            t2 = ""
        }
    }
    const mixpanelEvent = t2 === "FORM" ? "clientAcquisitionSurvey" : "clientAcquisitionAI";
    return({"t2": t2, "mixpanelEvent": mixpanelEvent});
}