import React, { useState } from 'react';
import styled from 'styled-components';

/* Components */
import { FontBody21 } from "../../../components/fonts";
import { colors } from "../../../components/colors";

const Relative = styled(FontBody21)`
  position: relative;
  line-height: 2;
  margin-top: 20px;
  color: ${props => props.$color};
  @media screen and (max-width: 748px) {
    font-size: 18px !important;
  }
`;
const Cursor = styled(FontBody21)`
  cursor: pointer;
  width: fit-content;
  margin: 0 0 0 auto;
`;

export default function IntroDescription({
     description = ""
}) {
    const [readMore, openReadMore] = useState(false);

    return (
        <>
            {/* .match(/[^\n]+(?:\r?\n|$)/g) - splits lines by new lines, but also preserves the newlines */}
            {(description.length < 280 || readMore)
                ? <Relative $color={colors.primary100}>
                    {<span style={{"whiteSpace": "pre-wrap"}}>{description.match(/[^\n]+(?:\r?\n|$)/g).join('')}</span>}&nbsp;&nbsp;
                    {readMore && <Cursor onClick={() => openReadMore(false)}> <span style={{textDecoration: "underline"}}>Show less</span></Cursor>}
                </Relative>
                : <Relative $color={colors.primary100}>
                    {<span style={{"whiteSpace": "normal"}}>{description.substring(0, 280).match(/[^\n]+(?:\r?\n|$)/g).join('')}</span>}. . .&nbsp;&nbsp;
                    {!readMore && <Cursor onClick={() => openReadMore(true)}> <span style={{textDecoration: "underline"}}>Read more</span></Cursor>}
                </Relative>
            }
        </>
    )
}
