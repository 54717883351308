import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

/* Components */
import { images } from "../../components/images";
import { FontBody16, FontSubtitle28, FontTitle21 } from "../../components/fonts";
import { colors } from "../../components/colors";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/button";

const Logo = styled.div`
  padding: 30px 0;
  text-align: center;
`;
const Container = styled.div`
  width: 748px;
  margin: 0 auto;
  @media screen and (max-width: 748px) {
    padding: 0 20px;
    width: calc(100% - 40px);
  }
`;
const Center = styled(FontTitle21)`
  text-align: center;
  margin: 0 auto;
`;
const Doggy = styled.img`
  height: 100%;
  max-height: 300px;
  width: auto;
`;
const Title = styled(FontSubtitle28)`
  margin: 20px 0;
`;
const Body = styled.div`
  max-width: 360px;
  margin: 0 auto 20px auto;
`;
const ErrorText = styled(FontBody16)`
    color: ${colors.alert100};
    font-weight: bold;
`;

export default function Error() {
    const { search } = useLocation();
    const navigate = useNavigate();
    let queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [randVal, setRandVal] = useState(Math.random());

    const getTitle = () => {
        if (randVal < 0.33) return "I've had a ruff day, just taking a paws!";
        else if (randVal < 0.66)
            return "Gone to the beach!";
        else return "Someone is blocking your path!";
    };

    const getImage = () => {
        if (randVal < 0.33) return images.error1;
        else if (randVal < 0.66) return images.error2;
        else return images.error3;
    };

    useEffect(() => {
        console.log(randVal)
        const maybeErrorMessage = queryParams.get("message");
        if (maybeErrorMessage)
            setErrorMessage(`Error message: ${maybeErrorMessage}`);
        setIsLoading(false);
    }, []);

    return (
        <Container>
            <Logo><img src={images.logo} height={"50"} alt={""} /></Logo>
            {isLoading
                ? <Center>Loading!</Center>
                : <Center>
                    <Doggy src={getImage()} height={"400"} />
                    <Title>{getTitle()}</Title>
                    <Body>
                        <FontBody16>Looks like you hit an error. Sorry! We're working to fix it as fast as we can.</FontBody16>
                        <ErrorText>{errorMessage}</ErrorText>
                    </Body>
                    <Button
                        isValid={true}
                        clickable={true}
                        onClick={() => {navigate("/")}}
                        label={"Get Matched"}
                        icon={faArrowRight}
                        iconType={"fa"}
                        iconSide={"right"}
                        colorButtonValid={colors.primary100}
                        colorButtonInvalid={colors.background30}
                        colorTextValid={colors.white}
                        colorTextInvalid={colors.primary30}
                    />
                </Center>
            }
        </Container>
    );
};