import React from 'react';

/* Components */
import { colors } from "../../../components/colors";
import { Section, Body } from '../components/components';

/* Containers */
import IntroGreeting from "./introgreeting";
import IntroAttributes from './introattributes';
import IntroDescription from "./introdescription";
import IntroVideo from "./introvideo";

export default function Intro({
   name = "",
   intro = {
       description: "",
       video: null,
       animal: "",
       zodiac: "",
       birthOrder: "",
       hobby: "",
       mbti: "",
   }
}) {
    return (
        <Section $backgroundColor={colors.white}>
            <Body>
                <IntroGreeting name={name} />
                <IntroAttributes intro={intro} />
                <IntroDescription description={intro.description} />
                <IntroVideo intro={intro.video} />
            </Body>
        </Section>
    )
}