import React from 'react';
import styled from 'styled-components';
import { colors } from '../../components/colors';
import { useSelector } from "react-redux";

/* Components */
import Footer from "../../components/footer";
import { images } from '../../components/images';
import { FontTitle40, FontBody21 } from '../../components/fonts';

const Page = styled.div`
    background-color: ${props => props.$backgroundColor};
    @media screen and (max-width: 1088px) {
        background-color: ${colors.canvaGray};
    }
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
`;
const Content = styled.div`
    position: relative;
    height: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    box-sizing: border-box;
    @media screen and (max-width: 1088px) {
      padding: 15px;
    }
     overflow-y: auto;
`;
const Box = styled.div`
    position: relative;
    border-radius: 12px;
    background-color: ${colors.primary100};
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    flex: 1 1 0;
    align-content: center;
`;
const Inner = styled.form`
    margin: 30px 0;
    height: calc(100% - 5px - 60px);
    display: flex;
    flex-direction: row; 
    gap: 20px;
    padding: 0 30px;
    @media screen and (max-width: 1088px) {
        margin: 0;
        height: calc(100svh - 30px);
        padding: 0;
    }
`;
const Left = styled.div`
  color: white;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 1088px) {
    display: none;
  }
`;
const Icon = styled.img`
    width: 50px;
`;
const Right = styled.div`
    width: 60%;
    @media screen and (max-width: 1088px) {
        width: 100%;
    }
    background-color: white;
    border-radius: 10px;
    & iframe {
        border: none;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
`;

export default function AI() {
    const backgroundColor = colors.canvaGreen;
    const t1Store = useSelector(state => state.session.t1);

    return (
        <Page $backgroundColor={backgroundColor}>
            <Content>
                <Box>
                    <Inner>
                        <Left>
                            <Icon src={images.messageIcon} />
                            <FontTitle40>Get matched with a financial advisor you can truly connect with.</FontTitle40>
                            <FontBody21>Help us understand your financial goals, life journey and passions, so we can match you with a trusted and vetted financial advisor that meets your needs and will help you achieve your goals.</FontBody21>
                        </Left>
                        <Right>
                            <iframe src={"https://regimenai.com/?question=willow&t1="+t1Store+"&t2=AI&hideHeader=true&skipForm=true"}/>
                        </Right>
                    </Inner>
                </Box>
            </Content>
            <Footer />
        </Page>
    );
};