import React, {useEffect, useState} from 'react';

/* Containers */
import QText from './qtext';
import QZip from './qzip';
import QRadio from "./qradio";
import QCheck from "./qcheck";
import QEmail from './qemail';
import QAssets from "./qassets";
import QPhone from "./qphone";

export default function Q(props) {
    const [allQuestions, setAllQuestions] = useState(props.allQuestions)
    const q = props.q;
    const a = props.a;
    const setA = props.setA;
    const color = props.color;
    const [isForked, setIsForked] = useState(false)

    const onAnswer = (answer) => {
        setA({...a, ...answer})
    }



    const onFork = (injectedList, qNum) => {
        const questions =  [
            ...allQuestions.slice(0, qNum),
            ...injectedList,
            ...allQuestions.slice(qNum)
        ];
        props.updateQuestions(allQuestions.length + injectedList.length)
        setAllQuestions(questions)
    }


    return (
        <>
            {allQuestions.map((i, iIndex) => {
                if(q === (iIndex+1)) {
                    if(i.type === "qText") {
                        return(<QText key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "qZip") {
                        return(<QZip key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "QRadio") {
                        return (<QRadio key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} onFork={onFork}/>)
                    } else if(i.type === "QCheck") {
                        return(<QCheck key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "qEmail") {
                        return(<QEmail key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "qAssets") {
                        return(<QAssets key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    } else if(i.type === "QPhone") {
                        return(<QPhone key={iIndex} q={q} a={a} onAnswer={onAnswer} i={i} color={color} />)
                    }
                }
            })}
        </>
    );
};