import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from "styled-components";

/* Components */
import { colors } from '../../../components/colors';

const Dot = styled.button`
    -webkit-tap-highlight-color: rgba(${colors.primary100}, 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:after {
        ${props => props.$isSelected && css`
            box-shadow: inset 0 0 0 0.2rem ${colors.primary100};
            background-color: ${colors.primary100};
        `}
        ${props => !props.$isSelected && css`
            box-shadow: inset 0 0 0 0.2rem ${colors.background30};
            background-color: ${colors.background30};
        `}
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        content: '';
    } 
`;

export const useDotButton = (emblaApi) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState([])

    const onDotButtonClick = useCallback(
        (index) => {
            if (!emblaApi) return
            emblaApi.scrollTo(index)
        },
        [emblaApi]
    )

    const onInit = useCallback((emblaApi) => {
        setScrollSnaps(emblaApi.scrollSnapList())
    }, [])

    const onSelect = useCallback((emblaApi) => {
        setSelectedIndex(emblaApi.selectedScrollSnap())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onInit(emblaApi)
        onSelect(emblaApi)
        emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)
    }, [emblaApi, onInit, onSelect])

    return {
        selectedIndex,
        scrollSnaps,
        onDotButtonClick
    }
}

export const DotButton = (props) => {
    const { children, ...restProps } = props

    return (
        <Dot type="button" {...restProps}>
            {children}
        </Dot>
    )
}
