import React, { useState, useRef } from 'react';
import styled from 'styled-components';

/* Components */
import { colors } from "../../../components/colors";
import { images } from "../../../components/images";

const ImageIcon = styled.img`
  width:100px;
  height:100px;
  cursor: pointer;
`;
const Fixed = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${colors.black}B3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const VideoBox = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  width: 80%;
  height: auto;
`;
const Close = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  padding: 10px;
  font-size: 50px;
  line-height: 50px;
  width: 50px;
  @media screen and (max-width: 720px) {
    top: -15px;
    right: -15px;
    padding: 5px;
    font-size: 40px;
    line-height: 40px;
    width: 40px;
  }
  background-color: ${colors.action100};
  color: ${colors.white};
  border-radius: 100%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export default function IntroVideo({
  video = null
}) {
    const [showVideo, setShowVideo] = useState(false)
    const videoRef = useRef(null)

    const openVideo = () => {
        setShowVideo(true);
    }
    const closeVideo = () => {
        videoRef.current.pause();
        setShowVideo(false);
    }

    return (
        <>
            {(video !== null && video !== "" && video !== undefined) &&
                <ImageIcon
                    onClick={openVideo}
                    leftOffset={3}
                    src={images.videoIcon}
                    alt={"video icon"}
                />
            }
            {showVideo &&
                <Fixed onClick={closeVideo}>
                    <VideoBox onClick={closeVideo}>
                        <Close onClick={closeVideo}>&times;</Close>
                        <video ref={videoRef} src={video} width={"100%"} height={"auto"} controls={true} />
                    </VideoBox>
                </Fixed>
            }
        </>
    )
}
