import React, {useState} from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

/* Components */
import { colors } from '../../../components/colors';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../components/button';

/* Middleware */
import { tryPostSurveyResponse } from "../middleware/postresponse";
import {
    tryPostMailchimp,
    tryPostMixpanelEnrich,
    tryFacebookTrack,
    tryGoogleTag,
    tryPostMixpanel,
    tryStackAdaptTag,
    tryGetGoogleTagClientId
} from "../../../utils/tracking";
import { tryClientRequest } from '../../profile/middleware/profile';
import {images} from "../../../components/images";
import {tryPostAbstractPhone} from "../middleware/getabstractphone";
import {tryPostAbstractEmail} from "../middleware/getabstractemail";

const ButtonContainer = styled.div`
    margin-top: 40px;
    display: flex;
    width: 100%;
    max-width: 750px;
  //width: 760px;
  //align-self: center;
  //flex-grow:0;
  //flex-shrink:2;
  //  justify-content:  flex-start;
    & button {
      height: 50px !important;
      border-radius: 30px;
      font-family: 'DMSans',serif !important;
      //@media screen and (max-width: 780px) {
      //  width:100%;
      //  margin-left: 0;
      //  margin-right: 0;
      //}
    }

  //@media screen and (max-width: 780px) {
  //  width:calc(110% - 1px);
  //  justify-content: flex-start;
  //  margin-left:50px;
  //
  //}
  
  //margin-right: ${props => props.q === 1 ? "50px" : 0};
`;

export default function Next(props) {
    const q = props.q;
    const qTotal = props.qTotal;
    const setQ = props.setQ;
    const a = props.a;
    const color = props.color;
    const onFinish = props.onFinish;
    const setA = props.setA;
    const t1Store = useSelector(state => state.session.t1);
    const t2Store = useSelector(state => state.session.t2);
    const utmCampaign = useSelector(state => state.session.utm_campaign)
    const utmContent = useSelector(state => state.session.utm_content)
    const utmTerm = useSelector(state => state.session.utm_term)
    const utmSource = useSelector(state => state.session.utm_source)
    const utmMedium = useSelector(state => state.session.utm_medium)

    const onForward = async (e) => {
        e.preventDefault();
        console.log('hello')
        console.log(a["q"+q])
        if(a["q"+q]?.isValid) {
            const dataKey = Object.keys(a["q"+q]).filter(f => {return(f !== "isValid" && f !== "zip" && f !== "assets" && f !== "dataKey2")})[0];

            // Double check email validation
            //if(dataKey === "email") {
                //const emailAbstract = a["q" + q][dataKey];
                //const isValidEmailAbstract = await tryPostAbstractEmail(emailAbstract);
                //if(!isValidEmailAbstract.data.valid) {
                //    return setA({...a, ...{["q"+q]: {[dataKey]: a["q"+q][dataKey], "isValid": isValidEmailAbstract.data.valid, "dataKey2": a["q"+q].dataKey2}}})
                //}
            //}

            // Double check phone validation
            if(dataKey === "phone") {
                const phoneAbstract = a["q" + q][dataKey].replace(/[^0-9]/g, '');
                const isValidPhoneAbstract = await tryPostAbstractPhone(phoneAbstract);
                if(!isValidPhoneAbstract.data.valid) {
                    return setA({...a, ...{["q"+q]: {[dataKey]: a["q"+q][dataKey], "isValid": isValidPhoneAbstract.data.valid, "dataKey2": a["q"+q].dataKey2}}})
                }
            }

            const req = {
                "anonymousId": t1Store,
                [dataKey]: a["q"+q][dataKey],
                "dataKey": a["q"+q].dataKey2,
                "value": a["q"+q][dataKey],
                "googleClientId": tryGetGoogleTagClientId(),
                "utmData": {
                    custom_source: utmSource,
                    custom_content: utmContent,
                    custom_term : utmTerm,
                    custom_medium: utmMedium,
                    custom_campaign: utmCampaign
                }
            }
            tryPostSurveyResponse(req);
            if (dataKey === "firstName") {
                tryStackAdaptTag('dfvmhrbc2Pv7114ksCEXjk')
                tryGoogleTag('custom_survey_start', {
                    custom_source: utmSource,
                    custom_content: utmContent,
                    custom_term : utmTerm,
                    custom_medium: utmMedium,
                    custom_campaign: utmCampaign
                });
            }
            if(dataKey === "firstName" || dataKey === "email") {
                let reqMixpanel = {
                    "anonId": t1Store,
                    [dataKey]: a["q"+q][dataKey],
                    "event": dataKey === "firstName" ? "clientAcquisitionSurveyCaptureFirstName" : "clientAcquisitionSurveyCaptureEmail"
                }
                tryPostMixpanelEnrich(reqMixpanel)
            }
            if(dataKey === "email") {
                tryStackAdaptTag('9NWo1e1uF9yaJ7QToLXltQ')
                tryPostMailchimp({"t1": t1Store, "tagId": "ACQEMAIL", "tagValue": "TRUE"})
                tryPostMailchimp({"t1": t1Store, "tagId": "ANONID", "tagValue": t1Store})
                tryGoogleTag('custom_submit_email', {
                    custom_source: utmSource,
                    custom_content: utmContent,
                    custom_term : utmTerm,
                    custom_medium: utmMedium,
                    custom_campaign: utmCampaign
                })
            }
            if(dataKey === "ropt") {
                tryStackAdaptTag('VoeHcJP6bKzqIc4HdHQUmZ')
                tryPostMailchimp({"t1": t1Store, "tagId": "ACQASSETS", "tagValue": "TRUE"})
                tryGoogleTag('custom_submit_assets', {
                    custom_source: utmSource,
                    custom_content: utmContent,
                    custom_term : utmTerm,
                    custom_medium: utmMedium,
                    custom_campaign: utmCampaign
                })

            }
            let phone = null;
            if(dataKey === "phone") {
                tryStackAdaptTag('BVbJsyGqod2twQ7wV5al7f')
                phone = a["q"+q][dataKey].replace(/[^0-9]/g, '');
                tryPostMailchimp({"t1": t1Store, "tagId": "PHONE", "tagValue": phone})
                tryPostMailchimp({"t1": t1Store, "tagId": "SMSPHONE", "tagValue": phone})
            }
            if(dataKey === "email" || dataKey === "ropt" || dataKey === "phone") {
                tryPostMailchimp({"t1": t1Store, "tagId": "ACQUPDATED", "tagValue": DateTime.now().toFormat("MM/dd/yyyy")})
            }
            if(q < qTotal) {
                return setQ(q + 1)
            }
            let ropt;
            let st;
            await Promise.all(Object.keys(a).map(i => {
                if(Object.keys(a[i]).includes("ropt")) {ropt = a[i].ropt}
                if(Object.keys(a[i]).includes("state")) {st = a[i].state}
            }));
            tryPostMailchimp({"t1": t1Store, "tagId": "ACQAIDONE", "tagValue": "TRUE"})
            tryPostMailchimp({"t1": t1Store, "tagId": "ACQUPDATED", "tagValue": DateTime.now().toFormat("MM/dd/yyyy")})
            // phone moved from profile to survey end
            const request = {
                "advisorId": null, //advisorId,
                "email": null,
                "timeslot": null,
                "phone": phone,
                "anonymousId": t1Store,
                "requestType": t2Store,
                "leadInfo": null,
                "firstName": null,
                "lastName": null,
            };
            await tryClientRequest(request)
            const reqMixpanel = {
                "userEmail": null,
                "anonId": t1Store,
                "event": "clientAcquisitionAdvisorRequestCall",
                "environment": "PRODUCTION",
                "custom_source": utmSource,
                "custom_content": utmContent,
                "custom_term" : utmTerm,
                "custom_medium": utmMedium,
                "custom_campaign": utmCampaign,
                ...request
            }
            await tryPostMixpanel(reqMixpanel);
            // tryPostMailchimp({"t1": t1Store, "tagId": "ACQBOOKED", "tagValue": "TRUE"})
            tryFacebookTrack('SubmitApplication');
            tryGoogleTag('custom_generate_lead', {
                custom_source: utmSource,
                custom_content: utmContent,
                custom_term : utmTerm,
                custom_medium: utmMedium,
                custom_campaign: utmCampaign
            });
            tryGoogleTag('generate_lead');
            onFinish(ropt, st)
        }
    }




    return (
        <ButtonContainer>
            {/*<StackAdapt />*/}
            <Button
                isValid={a["q"+q]?.isValid}
                clickable={a["q"+q]?.isValid}
                onClick={onForward}
                label={q === qTotal ? "VIEW MATCHES" : "CONTINUE"}
                icon={images.arrowContinue}
                iconType={"img"}
                iconSide={"right"}
                colorButtonValid={colors.buttonActive}
                colorButtonInvalid={colors.buttonInactive}
                colorTextValid={colors.black}
                colorTextInvalid={colors.black}
                width={"100%"}
            />
        </ButtonContainer>
    );
};
