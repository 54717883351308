import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";

/* Components */
import { colors } from "../../components/colors";
import { LogoLoading } from "../../components/loading";

/* Container */
import Photo from './containers/photo';
import Intro from "./containers/intro";
// import Contact from "./containers/contact";
import Expertise from "./containers/expertise";
import Firm from "./containers/firm";
import Disclosure from './containers/disclosure';
import ScrollUp from "./containers/scrollup";

/* Middleware */
import { tryGetCertifiedAdvisor } from "./middleware/profile";

/* Utils */
import { tryPostMailchimp, tryPostMixpanel } from "../../utils/tracking";
import { checkT1 } from "../../utils/checks/checkt1";
import { checkT2 } from "../../utils/checks/checkt2";
import { checkMatched } from "../../utils/checks/checkmatched";

/* Actions */
import {setT1, setMatched, setT2} from "../../utils/store";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    @media screen and (max-width: 980px) {
      flex-wrap: wrap;
    }
    height: 100vh;
    
    & .right-transition-enter {
        opacity: 0;
    }
    & .right-transition-enter-active {
        opacity: 1;
        transition: opacity 200ms;
    }
`;
const Left = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(100% - 860px);
    @media screen and (max-width: 1680px) {
        width: calc(100% - 725px);
    }
    @media screen and (max-width: 980px) {
        position: relative;
        width: 100%;
        height: 580px;
    }
    @media screen and (max-width: 490px) {
        height: 360px;
    }
    z-index: 1;
    background-color: ${colors.background30};
`;
const Right = styled.div`
    padding-left: calc(100% - 860px);
    width: 860px;
    overflow-y: auto;
    z-index: 2;
    justify-content: center;
    @media screen and (max-width: 1680px) {
        padding-left: calc(100% - 725px);
        width: 725px;
    }
    @media screen and (max-width: 980px) {
        padding-left: 0;
        width: 100%;
    }
`;

export default function IFrameAdvisorProfileV2() {
    const [advisor, setAdvisor] = useState({
        "id": 0,
        "firstName": "",
        "lastName": "",
        "photos": [],
        "intro": { "video": "", "description": "", "animal": "", "mbti": "", "hobby": "", "zodiac": "", "birthOrder": "" },
        "expertise": [],
        "specialities": [],
        "services": [],
        "disclosure": null,
        "logos": [],
        "firmSec": null,
        "advisorSec": null,
        "firmName": null,
        "firmBio": null,
        "firmLogo": null,
        "firmAddress": {},
        "isLoading": true
    });
    const t1Store = useSelector(state => state.session.t1);
    const t2Store = useSelector(state => state.session.t2);
    const matchedStore = useSelector(state => state.session.matched);
    const contactRef = useRef(null)
    const expertiseRef = useRef(null)
    const firmRef = useRef(null)
    const disclosureRef = useRef(null)
    const pageTopRef = useRef(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const utmCampaign = useSelector(state => state.session.utm_campaign)
    const utmContent = useSelector(state => state.session.utm_content)
    const utmTerm = useSelector(state => state.session.utm_term)
    const utmSource = useSelector(state => state.session.utm_source)
    const utmMedium = useSelector(state => state.session.utm_medium)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {

        // User ID
        const {t1, isNewConsumer} = await checkT1(t1Store);
        if (isNewConsumer) {
            return navigate("/");
        }
        await dispatch(setT1({"t1": t1}));

        // Already Matched
        const matched = await(checkMatched(isNewConsumer, matchedStore));
        await dispatch(setMatched({"matched": matched}));
        if(!matched && process.env.NODE_ENV !== "development") {
            return navigate("/");
        }

        // Collection Method
        const { t2, mixpanelEvent } = await checkT2(t2Store);
        if (t2 === undefined || t2 === "") {
            return navigate("/");
        }
        await dispatch(setT2({"t2": t2}));

        const url = location.pathname.split('/');
        const advisorHandle = url[url.length - 1];
        const advisorResults = await tryGetCertifiedAdvisor(advisorHandle)

        setAdvisor({
            ...advisorResults.data,
            "photos": advisorResults.data.photos.filter(p => { return (p !== null) }),
            "logos": advisorResults.data.logos.filter(l => { return (l !== null) }),
            "expertise": advisorResults.data.expertise !== null ? advisorResults.data.expertise : [],
            "isLoading": false
        });

    }

    useEffect(() => {
        if(!advisor.isLoading) {
            runTracking()
        }
    }, [advisor])

    const runTracking = () => {
        const reqMixpanel = {
            "userEmail": null,
            "anonId": t1Store,
            "event": "clientAcquisitionViewAdvisorProfile",
            "environment": "PRODUCTION",
            "advisorId": advisor.id,
            "advisorName": advisor.firstName+" "+advisor.lastName,
            custom_source: utmSource,
            custom_content: utmContent,
            custom_term : utmTerm,
            custom_medium: utmMedium,
            custom_campaign: utmCampaign
        }
        tryPostMixpanel(reqMixpanel);
        tryPostMailchimp({"t1": t1Store, "tagId": "ACQPROFILE", "tagValue": "TRUE"})
        tryPostMailchimp({"t1": t1Store, "tagId": "ACQUPDATED", "tagValue": DateTime.now().toFormat("MM/dd/yyyy")})
    }

    if (advisor.isLoading) {
        return (<LogoLoading pad={60} />)
    }

    return (
        <Row>
            <Left>
                <Photo photos={advisor.photos} />
            </Left>
            <Right>
                <div ref={pageTopRef}>
                    <Intro
                      name={advisor.firstName}
                      intro={advisor.intro}
                    />
                </div>
                {/*<div ref={contactRef}>*/}
                {/*    <Contact*/}
                {/*      advisorId={advisor.id}*/}
                {/*      advisorFirstName={advisor.firstName}*/}
                {/*    />*/}
                {/*</div>*/}
                <div ref={expertiseRef}>
                    <Expertise
                      expertise={advisor.expertise}
                    />
                </div>
                <div ref={firmRef}>
                    <Firm
                      firm_name={advisor.firmName}
                      firm_bio={advisor.firmBio}
                      firm_logo={advisor.logos.length > 0 ? advisor.logos[0] : advisor.firmLogo}
                      firm_address={advisor.firmAddress.address}
                      firm_sec={advisor.firmSec}
                    />
                </div>
                <div ref={disclosureRef}>
                    <Disclosure
                      disclosure={advisor.disclosure}
                      advisor_sec={advisor.advisorSec}
                    />
                </div>
                <ScrollUp scrollToTop={() => pageTopRef.current.scrollIntoView({ behavior: 'smooth' })} />
            </Right>
        </Row>
    )
}