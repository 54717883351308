import React from 'react';
import styled from 'styled-components';

/* Local */
import {images} from "./images";

const Rotate = styled.div`
    padding-top: ${props => props.$pad}px;
    height: calc(100svh - ${props => props.$pad}px);
    text-align: center;
    @keyframes rotation {
      from {transform: rotate(0deg);}
      to {transform: rotate(359deg);}
    }
    & img {
        animation: rotation 5s infinite linear;
    }
`;

export const LogoLoading = ({pad = 0}) => (
    <Rotate $pad={pad}>
        <img src={images.logoIcon} height="200" width="200" alt={"Loading!"} />
    </Rotate>
);