import styled, { css } from "styled-components";
import {FontHeader14, FontHeader18, FontHeader48, fonts, FontSubtitle20} from "../../../components/fonts";
import { colors } from "../../../components/colors";
import React from "react";

export const Title = styled(FontHeader48)`
    font-size: 32px;
    line-height: 1.2;
    @media screen and (max-width: 1088px) {
        font-size: 20px;
    }
    font-weight: bold;
    margin: 10px auto 0 auto;
    text-align: center;
`;

export const Subtitle = styled(FontHeader18)`
    font-size: 14px;
    margin: 0 auto 20px auto;
    text-align: center;
    color: ${colors.primary30};
    max-width: 800px;
`;

export const Input = styled.input`
    padding: 16px 32px;
    width: 682px;
    font-size: 24px;
    text-align: center;
    font-family: ${fonts.body.fontFamily};
    ${props => props.$isValid && css`
        border: 2px solid ${colors.background30};
    `}
    ${props => !props.$isValid && css`
        border: 2px solid ${colors.alert100};
    `}
    &:focus {
        outline: none;
        ${props => props.$isValid && css`
            border: 2px solid ${colors.action100} !important;
        `}
        ${props => !props.$isValid && css`
            border: 2px solid ${colors.action100} !important;
        `}
    }
  @media screen and (max-width: 780px) {
    width:calc(100% - 68px);
  }
`;

export const CleaveContainer = styled.div`
    & > input:focus {
        outline: none;
        ${props => props.$isValid && css`
            border: 2px solid ${colors.action100} !important;
        `}
        ${props => !props.$isValid && css`
            border: 2px solid ${colors.action100} !important;
        `}
    }
`;

export const IconTop = styled(FontHeader18)`
    display: none;
    @media screen and (max-width: 1088px) {
        display: block; 
    }
`;

export const IconBtns = styled(FontHeader18)`
    display: block;
    @media screen and (max-width: 1088px) {
        display: none; 
    }
`;

export const OptionTitle = styled(FontHeader14)`
    font-size: 16px;
    text-align: center;
`;

export const Boxes = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 700px;
    width: 100%;
`;

export const Box = styled.div`
  background-color: ${colors.white};
  color: ${colors.black};
    ${props => props.$slim && css`
        width: fit-content;
    `};
    ${props => !props.$slim && css`
        max-width: 160px;
        min-height: 84px;
        width: calc(100% - 40px);
        @media screen and (max-width: 1088px) {
            max-width: 100px;
            min-height: 42px;
        }
        @media screen and (max-width: 400px) {
            max-width: 75px;
        }
    `};
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    gap: 8px;
    cursor: pointer;
    ${props => props.$selected && css`
        //box-shadow: inset 0 0 20px 0 ${props => props.$color};
        background-color: ${props => props.$color};
        color: ${colors.black};
        & svg {
            color: ${colors.white} !important;
        }
    `}
`;

export const InputDiv = styled.div`
  display:flex;
  flex-direction: column;
  align-self:center;
  flex-grow: 0;
  width: 100%;
  max-width: 750px;
  @media screen and (max-width: 780px){
    //width:100vw;
    //margin-top:100px;
    //margin-left:20px;
    //width: 100%;
    margin-top:40px;
  }
  
`;
