import {axiosReq} from "./axios";

export const tryPostMixpanelEnrich = (req) => {
    if(process.env.NODE_ENV !== "development" && !window.location.hostname.includes("staging") ) {
        axiosReq('apiv1/mixpanel/enrich', "POST", req, {});
    }
};

export const tryPostMixpanel = (req) => {
    if(process.env.NODE_ENV !== "development" && !window.location.hostname.includes("staging")) {
        axiosReq('apiv1/mixpanel', "POST", req, {});
    }
};

export const tryPostMailchimp = (req) => {
    if(process.env.NODE_ENV !== "development" && !window.location.hostname.includes("staging")) {
        axiosReq('apiv1/mailchimp/save-tags', "POST", req, {})
    }
}

export const tryFacebookTrack = (event) => {
    if(process.env.NODE_ENV !== "development" && !window.location.hostname.includes("staging")) {
        fbq('track', event);
    }
}

export const tryGetGoogleTagClientId = () => {
    if(process.env.NODE_ENV !== "development" && !window.location.hostname.includes("staging")) {
        let clientId = "";
        gtag('get', 'G-LQDFRVLQ7E', 'client_id', (clientID) => {
            clientId = clientID;
        });
        return clientId
    }
}

export const tryGoogleTag = (event, param={}) => {
    if(process.env.NODE_ENV !== "development" && !window.location.hostname.includes("staging")) {
        gtag('event', event, param);
    }
}

export const tryStackAdaptTag = (id) => {
    if(process.env.NODE_ENV !== "development" && !window.location.hostname.includes("staging")) {
        saq('conv', id);
    }
}