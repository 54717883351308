import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

/* Pages */
import Entry from './pages/entry';
import Matches from "./pages/matches/page";
import Profile from "./pages/profile/page";
import Error from "./pages/error/page";

export default function AllRoutes() {
    return(
        <Routes>
            <Route exact path={'/'} element={<Entry />} />
            <Route exact path={'/matches'} element={<Matches />} />
            <Route exact path={'/matches/:id'} element={<Profile />} />
            <Route exact path={'/error'} element={<Error />} />
            <Route path={'/*'} element={<Navigate to={'/'} replace />} />
        </Routes>
    )
}