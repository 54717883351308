import React from 'react';
import styled from 'styled-components';

/* Components */
import { colors } from "../../../components/colors";
import { Section, Body, ContactTitle } from '../components/components';
import { FontBody24 } from '../../../components/fonts';
import { faStar } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ServiceName = styled(FontBody24)`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  color: ${props => props.$color}B3;
  @media screen and (max-width: 1680px) {
    font-size: 16px;
    line-height: 2;
  }
`;
const ServiceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0;
  list-style: none;
  padding: 0;
`;
const PreviewWhite = styled(FontBody24)`
  color: ${props => props.$color}B3;
  line-height: 2;
  & strong {  
    color: ${props => props.$color};
  }
  @media screen and (max-width: 1680px) {
    font-size: 16px !important;
  }
`;

export default function Expertise({
  expertise= []
}) {
    return (
        <Section $backgroundColor={colors.canvaGreen}>
            <ContactTitle $color={colors.white}>Specialties</ContactTitle>
            <Body>
                <PreviewWhite $color={colors.white}>My specialties include: </PreviewWhite>
                <ServiceList>
                    {expertise.map((e, index) => (
                        <ServiceName key={index} $color={colors.white}>
                            <FontAwesomeIcon icon={faStar}/>
                            {e}
                        </ServiceName>
                    ))}
                </ServiceList>
            </Body>
        </Section>

    )
}