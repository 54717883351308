import Cookies from "js-cookie";

/* Utils */
import { testIfSet } from "./checkcommon";

export const checkMatched = async (isNewConsumer, matchedStore) => {
    const urlParams = new URLSearchParams(window.location.search);
    let matched = false;
    if(!isNewConsumer) {
        let matchedCookie = Cookies.get('matched');
        let matchedParam = testIfSet(urlParams.get('matched')) ? urlParams.get('matched') : undefined;
        if(testIfSet(matchedCookie)) {
            matched = matchedCookie === "true";
        } else if(testIfSet(matchedParam)) {
            matched = matchedParam === "true";
            await Cookies.set("matched", matched.toString());
        } else if(testIfSet(matchedStore)) {
            matched = matchedStore;
            await Cookies.set("matched", matched.toString());
        } else if(!testIfSet(matchedCookie) && !testIfSet(matchedParam)) {
            matched = false;
            await Cookies.set("matched", matched.toString());
        }
    }
    return(matched);
}
