import axios from "axios";

axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
    if (config.baseURL !== undefined && config.baseURL.startsWith("https") && process.env.NODE_ENV === "development") {
        config.baseURL = config.baseURL.replace("https", "http");
    }
    return config;
});

export const axiosReq = (url, method, json, devRes) => {

    if (process.env.NODE_ENV === "development") {
        return devRes
    }

    let apiUrl = "https://api.trustwillow.com/" + url;

    if (method === "GET") {
        return axios.get(apiUrl, { params: json }).then((data) => data);
    }

    if (method === "POST") {
        return axios.post(apiUrl, json).then((data) => data);
    }

};
