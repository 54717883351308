import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../../components/colors';
import { fonts } from '../../../components/fonts';

const Bar = styled.div`
  margin: 30px 20px 0 20px;
    height: 30px;
    background-color: ${colors.white};
    border-radius:30px;
    &::before {
        content: '${props => props.$progress}%';
        color: ${colors.white};
        text-align: right;
        font-weight: bold;
        font-size: 14px;
        font-family: ${fonts.body.fontFamily};
        ${props => props.$progress > 0 && css`
            //padding-right: 12px;
        `}
        ${props => props.$progress === 0 && css`
            //padding-right: 30px;
        `}
        padding-right: 30px;
        padding-left: 10px;
        padding-top: 6px;
        width: calc(${props => props.$progress}% - 80px);
        height: calc(30px - 6px);
        background-color: ${props => props.$color};
        position: absolute;

        border-radius:50px;
        ${props => props.$progress < 100 && css`
            //border-radius:50px;
        `}
        z-index: 1;
    }
    @media screen and (max-width: 1088px) {
        height: 16px;
        &::before {
            content: '';
            padding-top: 0;
            height: 16px;
        }
    }
    @media screen and (max-width: 780px) {
      margin: 20px 15px 0 15px;
    }
`;

export default function Progress(props) {
    const q = props.q;
    const qTotal = props.qTotal;
    const color = props.color;

    return (
        <Bar $progress={q === 1 ? 0 : Math.round(q / qTotal * 100)} $color={color} />
    );
};