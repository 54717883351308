import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

/* Components */
import { colors } from "../../../components/colors";
import { FontBody16 } from "../../../components/fonts";
import { ContactTitle } from '../components/components';

const Title = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: ${({ $faded }) => $faded ? `fadeIn 1s ease-in-out` : `fadeIn 1s ease-in-out`};
`;
const Name = styled(FontBody16)`
    font-size: 28px;
    line-height: 1.2;
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;

export default function IntroGreeting({
  name = ""
}) {
    const greetings = ['Hello', 'Hola', 'Howdy', 'Welcome']
    const [greeting, setGreeting] = useState('Hello')
    const [faded, setFaded] = useState(false);

    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * greetings.length);
        setGreeting(greetings[index]);
        setFaded(false);
    }, []);

    useEffect(() => {
        const intervalID = setInterval(shuffle, 3000);
        setFaded(true);
        return () => clearInterval(intervalID);
    }, [shuffle])

    return (
        <>
            <Title $faded={faded}>
                <ContactTitle $color={colors.primary100}>{greeting}</ContactTitle>
            </Title>
            <Name>I'm {name}!</Name>
        </>
    )
}
