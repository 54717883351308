import React from 'react';
import styled from 'styled-components';

/* Components */
import { FontBody16, FontSubtitle28 } from "../../../components/fonts";

const Navbar = styled.div`
  background-color: #394950;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(100% - 60px);
  padding: 24px 30px;
`;
const Intro = styled.div`
  width: calc(100% - 60px);
  max-width: 900px;
  margin: 0 auto;
  padding: 0 30px; 
  display:flex;
  flex-direction:column;
  gap: 10px;
  text-align: center;
  @media screen and (max-width: 1088px) {
    padding: 0;
    width: 100%;
    & > div {
      font-size: 20px;
    }
    margin-bottom: -10px;
  }
`;

export default function Nav() {
    return (
        <>
            <Navbar>
                <img src="https://docs.trustwillow.com/logotransparentwhite.png" height="32" width="160" alt="Willow"/>
            </Navbar>
            <Intro>
                <FontSubtitle28 $spaced={true}>YOUR ADVISOR MATCHES</FontSubtitle28>
                {/*<FontBody16>Please review each advisor’s profile before selecting your top choice. Once you select an advisor, a member of the Willow team will reach out to review your choice before giving your information to the advisor.</FontBody16>*/}
            </Intro>
        </>
    );
};
