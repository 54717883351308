import {axiosReq} from "../../../utils/axios";

const devData = {
    data: {
        valid: true
    }
}

export const tryPostAbstractPhone = (phone) => {
    return axiosReq('advertising/public/leads/verify/phone', "POST", {"phone": phone},  devData);
};
