import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../components/colors';
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from "luxon";

/* Containers */
import Progress from './containers/progress'
import Q from './containers/q'
import Next from './containers/next'
import Back from "./containers/back";

/* Components */
import Footer from "../../components/footer";
import {images} from '../../components/images'

/* Questions */
import { initAllQuestions } from './containers/allquestions';

/* Middleware */
import {setRopt, setSt, setEmail} from "../../utils/store";
import {tryPostAbstractPhone} from "./middleware/getabstractphone";
import {tryFacebookTrack, tryGetGoogleTagClientId, tryGoogleTag, tryPostMailchimp, tryPostMixpanel, tryPostMixpanelEnrich, tryStackAdaptTag} from "../../utils/tracking";
import {tryPostSurveyResponse} from "./middleware/postresponse";
import {tryClientRequest} from "../profile/middleware/profile";

const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  backdrop-filter: blur(25px);
`;
const Background = styled.div`
  min-height: 100%;
  background-image: url("${images.surveyBackground}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;
const Content = styled.div`
    position: relative;
    height: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    box-sizing: border-box;
    @media screen and (max-width: 1088px) {
      padding: 15px;
    }
     overflow-y: auto;
`;
const Box = styled.div`
    position: relative;
    border-radius: 12px;
    width: 100%;
    max-width: 1068px;
    margin: 0 auto;
    flex: 1 1 0;
    align-content: center;
  background:transparent;
  color: white !important;
  font-family: LoraMedium, sans-serif !important;
  @media screen and (max-width: 780px) {
  //  margin: 10px;
    width: 100%;
  }
`;
const Inner = styled.form`
    margin: 30px 0;
    @media screen and (max-width: 400px) {
        margin: 0;
        height: 100%;
    }
    height: calc(100% - 5px - 60px);
    display: flex;
    flex-direction: column;
  @media screen and (min-width: 780px) {
    justify-content: safe center;
    align-items: center;
  }

    padding:0;
  
`;

export default function Survey() {
    const color = colors.action100;
    const backgroundColor = colors.canvaGray; // colors.canvaGreen;
    const [mailchimpDelay, setMailchimpDelay] = useState({"first": null, "last": null, "email": null, "sent": false})
    const [q, setQ] = useState({
        "allQuestions": initAllQuestions,
        "num": 1,
        "qTotal": initAllQuestions.length,
        "a": {},
        "inject": false
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t1Store = useSelector(state => state.session.t1);
    const t2Store = useSelector(state => state.session.t2);
    const utmData = {
        custom_source: useSelector(state => state.session.utm_source),
        custom_content: useSelector(state => state.session.utm_content),
        custom_term : useSelector(state => state.session.utm_term),
        custom_medium: useSelector(state => state.session.utm_medium),
        custom_campaign: useSelector(state => state.session.utm_campaign)
    }
    const surveyResponseBase = {
        "anonymousId": t1Store,
        "googleClientId": tryGetGoogleTagClientId(),
        "utmData": utmData
    }

    const onFinish = async (ropt, st) => {
        await dispatch(setRopt({"ropt": ropt}));
        await dispatch(setSt({"st": st}));
        return navigate("/matching")
    }

    const onForward = async (e) => {
        if(e !== null) {
            e.preventDefault();
        }
        if(q.a["q"+q.num]?.isValid) {
            const dataKey = Object.keys(q.a["q"+q.num]).filter(f => {return(f !== "isValid" && f !== "zip" && f !== "assets" && f !== "dataKey2" && f !== "dataKeyQuestion")})[0];

            if (dataKey === "needPartnerFor") {
                tryStackAdaptTag('dfvmhrbc2Pv7114ksCEXjk')
                tryGoogleTag('custom_survey_start', utmData);
            }

            if(dataKey === "fullName") {

                const first = q.a["q"+q.num][dataKey].first;
                const last = q.a["q"+q.num][dataKey].last
                await tryPostSurveyResponse({
                    ...surveyResponseBase,
                    "firstName": first,
                    "lastName": last,
                    "dataKey": "surveyFirstName",
                    "dataKeyQuestion": null,
                    "value": first
                });
                setMailchimpDelay({
                    ...mailchimpDelay,
                    "first": first,
                    "last": last
                })
                if(mailchimpDelay.sent) {
                    await tryPostMailchimp({
                        "email": mailchimpDelay.email,
                        "tags": {
                            "FNAME": first,
                            "LNAME": last,
                            "EMAIL": mailchimpDelay.email,
                            "ACQUPDATED": DateTime.now().toFormat("MM/dd/yyyy")
                        }
                    })
                }
                tryPostMixpanelEnrich({
                    "anonId": t1Store,
                    [dataKey]: q.a["q"+q.num][dataKey],
                    "event": "clientAcquisitionSurveyCaptureFirstName"
                })

            } else {

                await tryPostSurveyResponse({
                    ...surveyResponseBase,
                    [dataKey]: q.a["q" + q.num][dataKey],
                    "dataKey": q.a["q" + q.num].dataKey2,
                    "dataKeyQuestion": q.a["q" + q.num].dataKeyQuestion,
                    "value": q.a["q" + q.num][dataKey]
                });

            }

            if(dataKey === "email") {
                const email = q.a["q"+q.num][dataKey];
                // Double check email validation
                // const emailAbstract = q.a["q" + q.num][dataKey];
                // const isValidEmailAbstract = await tryPostAbstractEmail(emailAbstract);
                // if(!isValidEmailAbstract.data.valid) {
                //    return setQ({...q, "a": {...q.a, ...{["q"+q.num]: {[dataKey]: q.a["q"+q.num][dataKey], "isValid": isValidEmailAbstract.data.valid, "dataKey2": q.a["q"+q.num].dataKey2}}}})
                // }
                tryStackAdaptTag('9NWo1e1uF9yaJ7QToLXltQ')
                tryPostMixpanelEnrich({
                    "anonId": t1Store,
                    [dataKey]: q.a["q"+q.num][dataKey],
                    "event": "clientAcquisitionSurveyCaptureEmail"
                })
                tryGoogleTag('custom_submit_email', utmData)
                if(!mailchimpDelay.sent) {
                    const utmSource = utmData.custom_source === null ? {} : {"UTMSOURCE": utmData.custom_source};
                    const utmCampaign = utmData.custom_source === null ? {} : {"UTMCAMPAIG": utmData.custom_campaign};
                    const utmMedium = utmData.custom_source === null ? {} : {"UTMMEDIUM": utmData.custom_medium};
                    const utmContent = utmData.custom_source === null ? {} : {"UTMCONTENT": utmData.custom_content};
                    const utmTerm = utmData.custom_source === null ? {} : {"UTMTERM": utmData.custom_term};
                    await tryPostMailchimp({
                        "email": email,
                        "tags": {
                            "EMAIL": email,
                            "ANONID": t1Store,
                            "FNAME": mailchimpDelay.first,
                            "LNAME": mailchimpDelay.last,
                            ...utmSource,
                            ...utmCampaign,
                            ...utmMedium,
                            ...utmContent,
                            ...utmTerm,
                            "ACQEMAIL": "TRUE",
                            "ACQUPDATED": DateTime.now().toFormat("MM/dd/yyyy")
                        }
                    })
                    await dispatch(setEmail({"email": email}));
                    setMailchimpDelay({
                        ...mailchimpDelay,
                        "email": email,
                        "sent": true
                    })
                } else {
                    await tryPostMailchimp({
                        "email": mailchimpDelay.email,
                        "tags": {
                            "EMAIL": email,
                            "ACQUPDATED": DateTime.now().toFormat("MM/dd/yyyy")
                        }
                    })
                }
            }

            let phone = null;
            if(dataKey === "phone") {
                // Double check phone validation
                const phoneAbstract = q.a["q" + q.num][dataKey].replace(/[^0-9]/g, '');
                const isValidPhoneAbstract = await tryPostAbstractPhone(phoneAbstract);
                if(!isValidPhoneAbstract.data.valid) {
                    return setQ({...q, "a": {...q.a, ...{["q"+q.num]: {[dataKey]: q.a["q"+q.num][dataKey], "isValid": isValidPhoneAbstract.data.valid, "dataKey2": q.a["q"+q.num].dataKey2}}}})
                }
                tryStackAdaptTag('BVbJsyGqod2twQ7wV5al7f')
                phone = q.a["q"+q.num][dataKey].replace(/[^0-9]/g, '');
            }

            if(dataKey === "ropt" || (dataKey === "hasOtherTaxableAccounts" && q.a["q"+q.num][dataKey] === "No")) {
                tryStackAdaptTag('VoeHcJP6bKzqIc4HdHQUmZ')
                await tryPostMailchimp({
                    "email": mailchimpDelay.email,
                    "tags": {
                        "EMAIL": mailchimpDelay.email,
                        "ACQASSETS": "TRUE",
                        "ACQUPDATED": DateTime.now().toFormat("MM/dd/yyyy")
                    }
                })
                tryGoogleTag('custom_submit_assets', utmData)
            }

            // Survey Not Complete
            if(q.num < q.qTotal) {
                if(q.inject) {
                    return setQ({...q, "inject": false})
                } else {
                    return setQ({...q, "num": q.num + 1})
                }
            }

            // Survey Complete
            let ropt = 0;
            let st;
            await Promise.all(Object.keys(q.a).map(i => {
                if(Object.keys(q.a[i]).includes("ropt") || Object.keys(q.a[i]).includes("otherRetirementAccounts")) {ropt += q.a[i].assets}
                if(Object.keys(q.a[i]).includes("state")) {st = q.a[i].state}
            }));
            await tryPostMailchimp({
                "email": mailchimpDelay.email,
                "tags": {
                    "EMAIL": mailchimpDelay.email,
                    "ACQAIDONE": "TRUE",
                    "ACQUPDATED": DateTime.now().toFormat("MM/dd/yyyy")
                }
            })
            const request = {
                "advisorId": null,
                "email": null,
                "timeslot": null,
                "phone": phone,
                "anonymousId": t1Store,
                "requestType": t2Store,
                "leadInfo": null,
                "firstName": null,
                "lastName": null,
            };
            await tryClientRequest(request)
            await tryPostMixpanel({
                "userEmail": null,
                "anonId": t1Store,
                "event": "clientAcquisitionAdvisorRequestCall",
                "environment": "PRODUCTION",
                ...utmData,
                ...request
            });
            tryFacebookTrack('SubmitApplication');
            tryGoogleTag('custom_generate_lead', utmData);
            tryGoogleTag('generate_lead');
            await onFinish(ropt, st)
        }
    }

    const onFork = async (injectedList, qNum, onAnswerRecord) => {
        if(injectedList[0].dataKey !== q.allQuestions[qNum]?.dataKey) {
            const questions =  [
                ...q.allQuestions.slice(0, qNum),
                ...injectedList,
                ...q.allQuestions.slice(qNum)
            ];
            const afterForkAnswers = Object.fromEntries(Object.entries(q.a).slice(qNum));
            const afterForkAnswersAdjKeys = await Promise.all(Object.keys(afterForkAnswers).map((o, oIndex) => ({
                ["q"+(qNum + oIndex + 2)]: afterForkAnswers[o]
            })))
            const afterForkAnswersAdjObj = {}
            afterForkAnswersAdjKeys.forEach(i => {
                afterForkAnswersAdjObj[Object.keys(i)[0]] = i[Object.keys(i)[0]]
            });
            let injectType = injectedList[0].type;
            let injectAnswer = {[injectedList[0].dataKey]: injectedList[0].initValue, "assets": injectedList[0].initValue, "isValid": true, "dataKey2": injectedList[0].dataKey2, "dataKeyQuestion": injectedList[0].dataKeyQuestion}
            if(injectType === "qAssets") {
                injectAnswer = {...injectAnswer, "assets": injectedList[0].initValue}
            }
            const answers = {
                ...Object.fromEntries(Object.entries(q.a).slice(0,qNum-1)),
                ...onAnswerRecord,
                ...{["q" + (qNum + 1)]: injectAnswer},
                ...afterForkAnswersAdjObj
            }
            const newQ = {
                "inject": true,
                "allQuestions": questions,
                "qTotal": questions.length,
                "a": answers,
                "num": q.num + 1
            }
            setQ(newQ)
        }
    }

    useEffect(() => {
        if(q.a["q"+q.num]?.isRadio || q.inject) {
            onForward(null)
        }
    }, [q.a])

    return (
        <Background $backgroundColor={backgroundColor}>
            <Page>
                <Progress q={q.num} qTotal={q.qTotal} color={colors.green}/>
                <Content>
                    <Back q={q} setQ={setQ} />
                    <Box>
                        <Inner>
                            <Q q={q} setQ={setQ} color={color} onFork={onFork} onForward={onForward} />
                            {(q.allQuestions[q.num-1]?.type !== "QRadio" && q.qTotal !== q.num) &&
                                <Next q={q.num} qTotal={q.qTotal} a={q.a} onForward={onForward} />
                            }
                        </Inner>
                    </Box>
                </Content>
                <Footer />
            </Page>
        </Background>
    );
};