import styled, { css } from 'styled-components';

export const fonts = {
    body: {
        fontFamily: "MontserratRegular",
        fontFileName: "Montserrat-Regular.ttf",
        fontFormat: "truetype"
    },
    header: {
        fontFamily: "MontserratSemiBold",
        fontFileName: "Montserrat-SemiBold.ttf",
        fontFormat: "truetype"
    },
    subtitle: {
        fontFamily: "LoraMedium",
        fontFileName: "Lora-Medium.ttf",
        fontFormat: "truetype"
    },
    title: {
        fontFamily: "LoraBold",
        fontFileName: "Lora-Bold.ttf",
        fontFormat: "truetype"
    },
    headerV2: {
        fontFamily: 'Lora',
        fontFileName: 'Lora.ttf',
        fontFormat: 'truetype'
    },
    subtitleV2: {
        fontFamily: 'DMSans',
        fontFileName: 'DMSans.ttf',
        fontFormat: 'truetype'
    }
};

/* TITLE */

export const FontTitle40 = styled.div`
    font-family: ${fonts.title.fontFamily};
    font-size: 40px;
    line-height: 52px;
`;
export const FontTitle24 = styled.div`
    font-family: ${fonts.title.fontFamily};
    font-size: 24px;
    line-height: 36px;
`;
export const FontTitle21 = styled.div`
    font-family: ${fonts.title.fontFamily};
    font-size: 21px;
    line-height: 32px;
`;
export const FontTitle18 = styled.div`
    font-family: ${fonts.title.fontFamily};
    font-size: 18px;
    line-height: 27px;
`;



/* SUBTITLE */

export const FontSubtitle20 = styled.div`
  font-family: ${fonts.subtitleV2.fontFamily};
  font-size: 20px;
  line-height: 36px;
  ${props => props.$spaced && css`
      letter-spacing: 5.6px;
    `}
  ${props => !props.$spaced && css`
      letter-spacing: normal;
    `}
`

export const FontSubtitle28 = styled.div`
    font-family: ${fonts.subtitle.fontFamily};
    font-size: 28px;
    line-height: 36px;
    ${props => props.$spaced && css`
      letter-spacing: 5.6px;
    `}
    ${props => !props.$spaced && css`
      letter-spacing: normal;
    `}
    font-weight: medium;
`;

/* HEADER */

export const FontHeader48 = styled.div`
  font-family: ${fonts.headerV2.fontFamily};
  font-size: 48px;
  line-height: 60px;
  max-width:750px;
`
export const FontHeader18 = styled.div`
    font-family: ${fonts.header.fontFamily};
    font-size: 18px;
    line-height: 27px;
`;
export const FontHeader14 = styled.div`
    font-family: ${fonts.header.fontFamily};
    font-size: 14px;
    line-height: 21px;
`;



/* BODY */

export const FontBody24 = styled.div`
    font-family: ${fonts.body.fontFamily};
    font-size: 24px;
    line-height: 36px;
`;
export const FontBody21 = styled.div`
    font-family: ${fonts.body.fontFamily};
    font-size: 21px;
    line-height: 32px;
`;
export const FontBody18 = styled.div`
    font-family: ${fonts.body.fontFamily};
    font-size: 18px;
    line-height: 27px;
`;
export const FontBody16 = styled.div`
    font-family: ${fonts.body.fontFamily};
    font-size: 16px;
    line-height: 24px;
`;
export const FontBody14 = styled.div`
    font-family: ${fonts.body.fontFamily};
    font-size: 14px;
    line-height: 21px;
`;