import React, {useState} from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

/* Components */
import { images } from "../../../components/images";
import { colors } from "../../../components/colors";
import { FontTitle24, FontHeader14, FontTitle18, FontBody16, FontBody14 } from '../../../components/fonts';
import Button from '../../../components/button';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Rectangle = styled.div`
    display:flex;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - 60px);
    text-align: left;
    transition: opacity 1.25s ease-out 0s;
    background-color:${colors.background15};
    //box-shadow: 0 0.875rem 1.5rem 0 rgb(32 46 66 / 15%);
    padding: 30px;
    height: fit-content;
    border-radius: 20px;
    margin-bottom: 40px;
    @media screen and (max-width: 1088px) {
      gap: 10px;
      width: calc(100% - 40px);
      padding: 20px;
      scroll-snap-align: center;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
`;
const Top = styled.div`
    display:flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    text-align: left;
    transition: opacity 1.25s ease-out 0s;
    background-color:white;
    margin: -30px -30px 0 -30px;
    padding: 30px;
    @media screen and (max-width: 1088px) {
        gap: 20px;
        margin: -20px -20px 0 -20px;
        padding: 20px;
    }
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
`;
const Gap = styled.div`
    display:flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
`;
const ImageImg = styled.img`
  width: 100%;
  max-width: 300px;
  object-fit: cover;
  height: 100%;
  max-height:300px;
  border-radius:50%;
  aspect-ratio: 1 / 1;
  object-position: center top;
  @media screen and (max-width: 1088px) {
      max-width: 180px;
      max-height: 180px;
  }
`;
const NameText = styled(FontTitle24)`
    color:${colors.primary100};
`;
const CertText = styled(FontHeader14)`
    color:${colors.primary70};
`;
const Why = styled.div`
    background-color:${colors.background15};
    margin: 0 -30px -30px -30px;
    padding: 10px 30px 30px 30px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    @media screen and (max-width: 1088px) {
      margin: 0 -10px -20px -10px;
      padding: 0 10px 20px 10px;
    }
`;
const BioTextTitle = styled(FontTitle18)`
    color:${colors.primary100};
    //margin-bottom: 20px;
`;
const BioTextAll = styled(FontBody16)`
    margin-top: 10px;
    color:${colors.primary70};
`;
const ReadMoreless = styled(FontBody14)`
    margin-top: 10px;
    color:${colors.primary100};
    cursor: pointer;
    font-weight: 600;
`;
const BtnDiv = styled(Link)`
    display:flex;
    justify-content: center;
    & > button > div {
      border-color: ${colors.action100};
      background-color:${colors.action100};
    }
    & > button > div:hover {
      border-color: ${colors.action70};
      background-color:${colors.action70};
    }
`;
const ImageBox = styled.div`
    display: flex;
    justify-content: center;
`;

export default function Match(props) {
    const a = props.a;
    const [openCard, setOpenCard] = useState(false);

    const formatCertTitle = (cert) => {
        if(cert === "CERTIFIED ADVISOR FOR WOMEN™") {return("Advisor for Women™")}
        else if(cert === "CERTIFIED ADVISOR FOR NEXTGEN™") {return("Advisor for NextGen™")}
        else {return(cert)}
    }

    return (
        <Rectangle>
            <Top>
                <ImageBox>
                    <ImageImg src={a.photos === null ? images.logoIcon : a.photos} />
                </ImageBox>
                <Gap>
                    <NameText>{a.firstName+" "+a.lastName}</NameText>
                    <CertText>{formatCertTitle(a.certifications[0])}</CertText>
                </Gap>
                <BtnDiv to={"/matches/"+a.id}>
                    <Button
                        isValid={true}
                        clickable={true}
                        onClick={() => {}}
                        label={"View Profile"}
                        icon={faArrowRight}
                        iconType={"fa"}
                        iconSide={"right"}
                        colorButtonValid={colors.action100}
                        colorButtonInvalid={colors.action100}
                        colorTextValid={colors.white}
                        colorTextInvalid={colors.white}
                    />
                </BtnDiv>
            </Top>
            <Why>
                <BioTextTitle>
                    {a.title}
                    {!openCard && <ReadMoreless onClick={() => setOpenCard(true)}>Read more</ReadMoreless>}
                </BioTextTitle>
                {openCard && <BioTextAll>{a.explanation}</BioTextAll>}
                {openCard && <ReadMoreless onClick={() => setOpenCard(false)}>Read less</ReadMoreless>}
            </Why>
        </Rectangle>
    );
}