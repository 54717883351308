import React, { useState } from 'react';
import styled from 'styled-components';

/* Components */
import { FontBody16 } from "../../../components/fonts";
import { colors } from "../../../components/colors";
import { images } from "../../../components/images";
import { iconNames } from "../components/icons";

const Links = styled.div`
  display: flex;
  width:fit-content;
  height:100%;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 3;
  flex-grow:2;
  position: relative;
  column-gap:40px;
  row-gap:0;
  @media screen and (max-width: 1680px) {
    column-gap: 20px;
  }
  @media screen and (max-width: 980px) {
    column-gap: 40px;
  }
  @media screen and (max-width: 768px) {
    column-gap: 20px;
    flex-wrap: wrap;
  }
  align-items: center;
  justify-content: center;
`;
const ImageIconBox = styled.div`
  text-align: center;
  cursor:pointer;
`;
const ImageIcon = styled.img`
  width:100px;
  height:100px;
  cursor: pointer;
  offset-position: left;
`;
const Tooltip = styled.div`
  background-color: ${colors.primary100};
  width:100%;
  position: absolute;
  bottom: -40px;
  min-height:35px;
  border-radius:10px;
  padding:0.25em 0;
  justify-content: center;
  align-content: center;
  display:flex;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    left: ${props => props.$pos};
    right: auto;
    top: 0;
    border: 15px solid transparent;
    border-top: 0;
    border-bottom: 15px solid ${colors.primary100};
    transform: translate(-50%, -100%);
  }
  ${FontBody16} {
    color:${colors.white};
    align-self: center;
    width: calc(100% - 40px);
  }
  @media screen and (max-width: 768px) {
    gap: 20px;
    flex-wrap: wrap;
    &::before {
      display: none;
    }
  }
`;

export default function IntroAttributes({
  intro = {
      animal: "",
      zodiac: "",
      birthOrder: "",
      hobby: "",
      mbti: "",
  }
}) {
    const [hoverAnimal, setHoverAnimal] = useState(false)
    const [hoverHobby, setHoverHobby] = useState(false)
    const [hoverMBTI, setHoverMBTI] = useState(false)
    const [hoverZodiac, setHoverZodiac] = useState(false)
    const [hoverBirthOrder, setHoverBirthOrder] = useState(false)

    const isAnimal = (intro.animal !== "" && intro.animal !== undefined)
    const isZodiac = (intro.zodiac !== "" && intro.zodiac !== undefined)
    const isBirthOrder = (intro.birthOrder !== "" && intro.birthOrder !== undefined)
    const isHobby = (intro.hobby !== "" && intro.hobby !== undefined)
    const isMBTI = (intro.mbti !== "" && intro.mbti !== undefined)

    const animal = isAnimal ? images[iconNames.filter(icon => icon.includes(intro.animal))[0]] : ""
    const zodiac = isZodiac ? images[iconNames.filter(icon => icon.includes(intro.zodiac))[0]] : ""
    const birthOrder = isBirthOrder ? images[iconNames.filter(icon => icon.includes(intro.birthOrder.replace(" ", "")))[0]] : ""
    let hobby = isHobby ? images[iconNames.filter(icon => icon.includes(intro.hobby))[0]] : ""
    const mbti = isMBTI ? images[iconNames.filter(icon => icon.includes(intro.mbti))[0]] : ""

    switch (intro.hobby) {
        case "Who has time for hobbies ?":
            hobby = images["WhoHasTimeForHobbiesIcon"];
            break;
        case "Knitting/Sewing":
            hobby = images["KnittingSewingIcon"];
            break;
        case "Coffee/Tea":
            hobby = images["CoffeeTeaIcon"];
            break;
        case "Movies/TV":
            hobby = images["MoviesTVIcon"];
            break;
        default:
            break;
    }

    return (
        <>
            {(isMBTI || isHobby || isAnimal || isBirthOrder || isZodiac) &&
                <Links>

                    {isMBTI &&
                        <ImageIconBox onMouseEnter={() => setHoverMBTI(true)} onMouseLeave={() => setHoverMBTI(false)}>
                            <ImageIcon src={mbti} alt={"MBTI"}  />
                        </ImageIconBox>
                    }
                    {isHobby &&
                        <ImageIconBox onMouseEnter={() => setHoverHobby(true)} onMouseLeave={() => setHoverHobby(false)}>
                            <ImageIcon src={hobby} alt={"Hobby"} />
                        </ImageIconBox>
                    }
                    {isAnimal &&
                        <ImageIconBox onMouseEnter={() => setHoverAnimal(true)} onMouseLeave={() => setHoverAnimal(false)}>
                            <ImageIcon src={animal} alt={"Animal"} />
                        </ImageIconBox>
                    }
                    {isBirthOrder &&
                        <ImageIconBox onMouseEnter={() => setHoverBirthOrder(true)} onMouseLeave={() => setHoverBirthOrder(false)}>
                            <ImageIcon src={birthOrder} alt={"Birth Order"} />
                        </ImageIconBox>
                    }
                    {isZodiac &&
                        <ImageIconBox onMouseEnter={() => setHoverZodiac(true)} onMouseLeave={() => setHoverZodiac(false)}>
                            <ImageIcon src={zodiac} alt={"Zodiac"} />
                        </ImageIconBox>
                    }

                    {hoverMBTI &&
                        <Tooltip $pos={zodiac !== "" ? '8%' : '12%'}>
                            <FontBody16>Myers-Briggs Personality Trait: {intro.mbti}</FontBody16>
                        </Tooltip>
                    }
                    {hoverHobby &&
                        <Tooltip $pos={zodiac !== "" ? '27%' : '37%'}>
                            <FontBody16>Favorite Hobby: {intro.hobby}</FontBody16>
                        </Tooltip>
                    }
                    {hoverAnimal &&
                        <Tooltip $pos={zodiac !== "" ? '47%' : '63%'}>
                            <FontBody16>Favorite Animal: {intro.animal}</FontBody16>
                        </Tooltip>
                    }
                    {hoverBirthOrder &&
                        <Tooltip $pos={zodiac !== "" ? '69%' : '87%'}>
                            <FontBody16>Birth Order: {intro.birthOrder}</FontBody16>
                        </Tooltip>
                    }
                    {hoverZodiac &&
                        <Tooltip $pos={'92%'}>
                            <FontBody16>Zodiac: {intro.zodiac}</FontBody16>
                        </Tooltip>
                    }

                </Links>
            }
        </>
    )
}
