import React from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/* Components */
import { colors } from "../../../components/colors";
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const EmptyIconButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: fixed; 
    bottom: 20px; 
    right:25px;
    padding: 4px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: ${colors.primary70};
    border: none;
    border-radius: 50%;
    font-size: 20px;
    line-height: 20px;
    color: ${colors.white};
    white-space: nowrap;
    transition: box-shadow .3s ease-in-out, background-color .3s ease-in-out;
    &:active, &:focus {
        outline: none;
        border: none;
    }
    &:hover:not(:disabled) {
        box-shadow: inset 0 0 10px 2px rgba(220, 220, 220, .4);
    }
    &:active:not(:disabled) {
        box-shadow: inset 0 0 10px 3px rgba(220, 220, 220, .8);
    }
    &:disabled {
        cursor: not-allowed;
        background-color: ${colors.primary30};
    }
`;

export default function ScrollUp({
     scrollToTop = () => {},
}) {
    return(
        <EmptyIconButton onClick={scrollToTop}>
            <FontAwesomeIcon icon={faArrowUp}/>
        </EmptyIconButton>
    )
}