import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";

/* Utils */
import { testIfSet } from "./checkcommon";

export const checkT1 = async (t1Store) => {
    const urlParams = new URLSearchParams(window.location.search);
    let isNewConsumer = false;
    let t1 = null;
    const t1Cookie = Cookies.get('t1');
    const t1Param = testIfSet(urlParams.get('t1')) ? urlParams.get('t1') : undefined;
    if(testIfSet(t1Cookie)) {
        t1 = t1Cookie;
        isNewConsumer = false;
    } else if(testIfSet(t1Param)) {
        t1 = t1Param;
        await Cookies.set("t1", t1);
        isNewConsumer = false;
    } else if(testIfSet(t1Store)) {
        t1 = t1Store;
        await Cookies.set("t1", t1);
        isNewConsumer = false;
    } else if(!testIfSet(t1Cookie) && !testIfSet(t1Param)) {
        const pathname = window.location.pathname;
        if(pathname === "/") {
            t1 = uuidv4();
            await Cookies.set("t1", t1);
        }
        isNewConsumer = true;
    }
    return({"t1": t1, "isNewCustomer": isNewConsumer});
}