import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import validator from "validator";
import Cleave from "cleave.js/dist/cleave-react-node";

/* Components */
import { Title, Subtitle, CleaveContainer } from '../components/q'
import { colors } from "../../../components/colors";
import {FontHeader48, fonts, FontSubtitle20} from "../../../components/fonts";

const inputsStyles = (isValid) => ({
    "padding": "16px 32px",
    "width": "calc(100% - 64px)",
    "maxWidth": "calc(380px - 128px)",
    "fontSize": "24px",
    "textAlign": "center",
    "fontFamily": fonts.body.fontFamily,
    "border": `2px solid ${isValid ? colors.background30 : colors.alert100}`,
});

export default function QPhone(props) {
    const a = props.a;
    const q = props.q;
    const icon = props.i.icon;
    const title = props.i.title;
    const subtitle = props.i.subtitle;
    const dataKey = props.i.dataKey;
    const dataKey2 = props.i.dataKey2;
    const inputName = props.i.inputName;
    const inputAutocomplete = props.i.inputAutocomplete;
    const onAnswer = props.onAnswer;
    const color = props.color;
    const [isNew, setIsNew] = useState(true);
    let inputRef;

    useEffect(() => {
        if(q > 1 || a["q"+q] !== undefined) {
            inputRef.focus();
        }
    }, [])

    const onChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        const isValid = validator.isMobilePhone(value, 'en-US')
        onAnswer({["q"+q]: {[dataKey]: value, "isValid": isValid, "dataKey2": dataKey2}})
        if(isNew) {
            setIsNew(false);
        }
    }

    return(
        <>
            {/*<FontAwesomeIcon*/}
            {/*    icon={icon}*/}
            {/*    style={{"color": color, fontSize: '30px'}}*/}
            {/*/>*/}
            <FontSubtitle20 style={{
                marginBottom: '20px',
            }} $spaced={true}>{subtitle}</FontSubtitle20>
            <FontHeader48
            >{title}</FontHeader48>
            <Subtitle></Subtitle>
            <CleaveContainer $isValid={isNew ? true : a["q"+q]?.isValid}>
                <Cleave
                    htmlRef={(ref) => inputRef = ref}
                    options={{numericOnly: true, blocks: [0, 3, 0, 3, 4], delimiters: ["(", ")", " ", "-"]}}
                    onChange={onChange}
                    placeholder={"(###) ###-####"}
                    value={a["q"+q]?.[dataKey]}
                    style={inputsStyles(isNew ? true : a["q"+q]?.isValid)}
                    name={inputName}
                    autoComplete={inputAutocomplete}
                />
            </CleaveContainer>
        </>
    )
}